import { api } from '@/plugins/axios';
import { findCommon } from '../../utils';

const newProject = {
  state: {
    newProjectData: null,
    contentTypes: null,
    currentStep: 0,
    projectDetailStepData: null,
    contentDetailStepData: null,
    autosavedContent: null,
    prices: null,
    currentPriceData: null,
    extraServices: null,
    discountCodeResponse: null,
    tempUserProfile: null,
    products: null,
  },

  getters: {
    newProjectData(state) {
      return state.newProjectData;
    },

    getProductInformation(state) {
      return state.products;
    },

    currentStep(state) {
      return state.currentStep;
    },

    contentTypes(state) {
      return state.contentTypes;
    },

    projectDetailStepData(state) {
      return state.projectDetailStepData;
    },

    contentDetailStepData(state) {
      return state.contentDetailStepData;
    },

    totalContentCount(state) {
      return state.contentDetailStepData.contents.length;
    },

    mostCommonLanguage({ contentDetailStepData: { contents } }, getters) {
      const mostCommonLangId = findCommon(contents, 'language');

      if (getters.newProjectData && getters.newProjectData.length > 0) {
        return getters.newProjectData.find(x => x.id === mostCommonLangId);
      }
    },

    mostCommonWriterLevel({ contentDetailStepData: { contents } }, getters) {
      const mostCommonWriterLevelId = findCommon(contents, 'writer_level');
      if (getters.mostCommonLanguage) {
        return getters.mostCommonLanguage.rel_language_writer_level.find(
          x => x.writer_level.id === mostCommonWriterLevelId
        );
      }
    },

    mostCommonInterest({ contentDetailStepData: { contents } }, getters) {
      const mostCommonInterestId = findCommon(contents, 'interest');

      if (getters.mostCommonWriterLevel) {
        return getters.mostCommonWriterLevel.writer_level.rel_writer_level_interest.find(
          x => x.interest.id === mostCommonInterestId
        );
      }
    },

    projectDetailStepResponseData(state) {
      return state.projectDetailStepResponseData;
    },

    autosavedContent(state) {
      return state.autosavedContent;
    },

    prices(state) {
      return state.prices;
    },

    currentPriceData(state) {
      return state.currentPriceData;
    },

    extraServices(state) {
      return state.extraServices;
    },

    discountCodeResponse(state) {
      return state.discountCodeResponse;
    },

    tempUserProfile(state) {
      return state.tempUserProfile;
    },
  },

  actions: {
    setCurrentStep({ commit }, payload) {
      commit('setCurrentStepHandler', payload);
    },
    deleteProject(context, { projectId }) {
      return api
        .delete(`project/${projectId}`)
        .then(response => {
          notify({
            type: 'success',
            group: 'success',
            text: response.data.detail,
          });
        });
    },
    getNewProjectData(context, payload) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/language`)
        .then(response => {
          context.commit('getNewProjectDataHandler', response.data);
        });
    },

    setProductInformation(context) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/product-settings`)
        .then(response => {
          context.commit('setProductInformationHandler', response.data);
        });
    },

    getContentTypes(context, payload) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/contenttype`)
        .then(response => {
          context.commit('getContentTypesHandler', response.data);
        });
    },

    increaseCurrentStep(context, payload) {
      context.commit('increaseCurrentStepHandler');
    },

    decreaseCurrentStep(context, payload) {
      context.commit('decreaseCurrentStepHandler', payload);
    },

    createAndSendProjectDetailStepData(context, payload) {
      const { newProjectName, newProjectBrief, selectedContentType } = payload;
      const requestData = {
        title: newProjectName,
        brief: newProjectBrief,
        content_type_id: selectedContentType.id,
      };

      return api.post('project', requestData).then(response => {
        context.commit('createAndSendProjectDetailResponseHandler', {
          data: payload,
          id: response.data.id,
        });
        context.commit(
          'createAndSendContentDetailResponseHandler',
          response.data
        );
      });
    },

    // Sets draft project data to vuex state
    setProjectDetailStepData(context, payload) {
      context.commit('setProjectDetailStepDataHandler', payload);
    },

    setContentDetailStepData(context, { makeRequest, project }) {
      if (!makeRequest) {
        context.commit('createAndSendContentDetailResponseHandler', project);
      } else {
        api.get(`project/${project.id}`).then(response => {
          context.commit(
            'createAndSendContentDetailResponseHandler',
            response.data
          );
        });
      }
    },

    createAndSendContentDetailStepData(
      context,
      { id, contentCount, word_min, writer_level, language, interest }
    ) {
      const requestData = {
        count: contentCount,
        word_min: Number(word_min),
        writer_level_id: writer_level.id,
        language_id: language.id,
        interest_id: interest.id,
      };

      return api
        .post(`project/${id}/content`, {
          ...requestData,
        })
        .then(response => {
          context.commit(
            'createAndSendContentDetailResponseHandler',
            response.data
          );
        });
    },

    autosaveContent(context, { projectId, content }) {
      const contentStr = typeof content.brief === 'string' ? content.brief : (content?.brief?.content && JSON.stringify(content.brief.content)) || null;
      const requestData = [
        {
          content_id: content.id,
          check_title: false,
          title: content.title,
          brief: contentStr,
          keywords: content.keywords.length
            ? content.keywords.map(x => x.text).join(',')
            : null,
          language_id: content.language.id,
          writer_level_id: content.writer_level.id,
          interest_id: content.interest.id,
          word_min: content.word_min,
        },
      ];

      return api
        .put(`project/${projectId}/content`, [...requestData])
        .then(response => {
          context.commit('autosaveContentHandler', response.data);
        });
    },

    getPrices(context, payload) {
      return api.get('info/price').then(response => {
        context.commit('setPricesHandler', response.data);
      });
    },

    setCurrentPriceData(context, payload) {
      context.commit('setCurrentPriceDataHandler', payload);
    },

    sendAllContents(context, payload) {
      // get project id from local state
      const projectId = context.state.projectDetailStepData.id;

      const requestData = [];
      // contents array
      payload.contents.forEach(x => {
        requestData.push({
          content_id: x.id,
          check_title: payload.checkTitle,
          title: x.title,
          brief: x.brief ? (typeof x.brief === 'object' ? (x?.brief?.content && JSON.stringify(x.brief.content)) || null : x.brief) : null,
          keywords:
            Array.isArray(x.keywords)
              ? x.keywords.map(x => x.text).join(',')
              : null,
          language_id: x.language.id,
          writer_level_id: x.writer_level.id,
          interest_id: x.interest.id,
          word_min: x.word_min,
        });
      });
      return api
        .put(`project/${projectId}/content`, [...requestData])
        .then(response => {
          context.commit('sendAllContentsHandler', response.data);
        });
    },

    getExtraServices(context, payload) {
      return api.get('info/extraservice').then(response => {
        context.commit('getExtraServicesHandler', response.data);
      });
    },

    payNewProjectFee(context, payload) {
      const projectId = context.state.projectDetailStepData.id;

      const requestData = {};
      return api.post('payment');
    },

    checkForDiscountCode(context, payload) {
      return api
        .post(`discountcode/${payload.code}`)
        .then(response => {
          context.commit('checkForDiscountCodeHandler');
        });
    },

    resetCurrentStep(context, payload) {
      context.commit('resetCurrentStepHandler');
    },

    setTempUserProfileForBilling(context, payload) {
      context.commit('setTempUserProfileForBillingHandler', payload);
    },
  },

  mutations: {
    setCurrentStepHandler(state, payload) {
      state.currentStep = payload;
    },
    setProductInformationHandler(state, payload) {
      state.products = payload;
    },
    setProjectDetailStepDataHandler(state, payload) {
      state.projectDetailStepData = payload;
    },

    resetCurrentStepHandler(state, payload) {
      state.currentStep = 0;
    },

    getNewProjectDataHandler(state, payload) {
      state.newProjectData = payload;
    },

    increaseCurrentStepHandler(state, payload) {
      state.currentStep++;
    },

    decreaseCurrentStepHandler(state, payload) {
      state.currentStep--;
    },

    getContentTypesHandler(state, payload) {
      state.contentTypes = payload;
    },

    createAndSendProjectDetailResponseHandler(state, payload) {
      state.projectDetailStepData = payload;
    },

    createAndSendContentDetailResponseHandler(state, payload) {
      state.contentDetailStepData = payload;
    },

    projectDetailStepResponseDataHandler(state, payload) {
      state.projectDetailStepResponseData = payload;
    },

    autosaveContentHandler(state, payload) {
      state.autosavedContent = payload;
    },

    setPricesHandler(state, payload) {
      state.prices = payload;
    },

    setCurrentPriceDataHandler(state, payload) {
      state.currentPriceData = payload;
    },

    sendAllContentsHandler(state, payload) {
      state.contentDetailStepData = payload;
    },

    getExtraServicesHandler(state, payload) {
      state.extraServices = payload;
    },

    checkForDiscountCodeHandler(state, payload) {
      state.discountCodeResponse = payload;
    },

    setTempUserProfileForBillingHandler(state, payload) {
      state.tempUserProfile = payload;
    },
  },
};

export default newProject;
