const balanceAndPayments = {
  state: {

  },

  getters: {

  },

  actions: {

  },

  mutations: {}
}

export default balanceAndPayments;