import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import VCalendar from 'v-calendar';
import AxiosPlugin from '@/plugins/axios';
import Helpers from '@/plugins/helpers';
import { initVue3Notification } from '@/plugins/vue-notification';
import { initSentry } from '@/plugins/sentry';
import '@/assets/styles/main.scss';
import '@/plugins/vee-validate';
import { initPinia } from '@/plugins/pinia';
import { initGlobalComponents } from './globalComponents';
import { initAutoAnimate } from '@/plugins/auto-animate';
import ModalPlugin from '@/plugins/v-modal';
import { initVClickOutside } from '@/plugins/v-click-outside';
import { initVueTippy } from '@/plugins/vue-tippy';
import { initUnhead } from '@/plugins/unhead';

const app = createApp(App);

initSentry(app);
initGlobalComponents(app);
initAutoAnimate(app);
initVue3Notification(app);
initVClickOutside(app);
initVueTippy(app);
initUnhead(app);

app.component('VCalendar', VCalendar);
app.use(AxiosPlugin);
app.use(Helpers);
app.use(initPinia);
app.use(ModalPlugin);

app.use(store);
app.use(router);
app.mount('#app');