import projectStatusEnums from '@/enums/projectStatusEnums'

const projects = {
  state: {
    projects: [],
  },

  getters: {
    projects(state) {
      return state.projects
    },
  },

  actions: {
    setProjects({ commit }, payload) {
      commit('setProjectsHandler', payload)
    },
  },

  mutations: {
    setProjectsHandler(state, payload) {
      state.projects = payload
    },
  },
}

export default projects
