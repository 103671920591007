const global = {
  state: {
    header_title : null,
    breadcrumb_data : null
  },

  getters: {
    getHeaderTitle(state) {
      return state.header_title
    },
    getBreadcrumbData(state) {
      return state.breadcrumb_data
    },
  },

  mutations: {
    setHeaderTitle(state, payload) {
      state.header_title = payload
    },
    setBreadcrumbData(state,payload) {
      state.breadcrumb_data = payload
    }
  },
}

export default global
