import { createRouter, createWebHistory } from 'vue-router';
import publisherRoutes from '@/router/publisher-routes';
import writerRoutes from '@/router/writer-routes';
import { authToken } from '@/utils/auth-token-utils';

const routesHash = {
  'publisher': publisherRoutes,
  'writer': writerRoutes,
};
const currentMode = import.meta.env.MODE;
const routes = routesHash[currentMode] || publisherRoutes;

const router = new createRouter({
  history: createWebHistory(),
  base: import.meta.env.VITE_BASE_URL,
  routes,
  linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  if(to.meta.protected && !authToken()) {
    window.location.href = import.meta.env.VITE_LOGIN_URL;
  } else {
    next();
  }
});
export default router;
