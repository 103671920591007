export function findCommon(arr, prop) {
  let max = 0,
    result,
    freq = 0

  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop].id === arr[i + 1]?.[prop].id) {
      freq++
    } else {
      freq = 0
    }
    if (freq > max) {
      result = arr[i][prop].id
      max = freq
    }
  }
  return result
}

export function convertFileToBase64String(file) {
  if (file) {
    let reader = new FileReader()

    var base64String = ''

    reader.onload = function(e) {
      let binaryString = e.target.result
      base64String = btoa(binaryString)
    }

    reader.readAsBinaryString(file)

    return base64String
  }
}
