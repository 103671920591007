<template>
  <div class="no-data" :style="{ height: `${height}px` }">
    <div v-if="icon" class="empty-folder">
      <i :class="icon"></i>
    </div>

    <p class="no-data-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'IcerikNoData',
  props: {
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/main'
.no-data
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  .no-data-text
    color: $gray
    margin-top: 16px
    text-align: center
    font-weight: 400
    font-family: 'DM Sans', sans-serif
    font-size: 14px
  .empty-folder
    i
      display: flex
      align-items: center
      justify-content: center
      padding: 20px 18px
      width: 64px
      height: 64px
      font-size: 28px
      color: $gray
      background: $light
      border-radius: 50%
</style>
