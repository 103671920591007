<script setup>
import { computed, defineProps, useAttrs } from 'vue';

const props = defineProps({
  theme: { type: String, default: 'primary' },
  type: { type: String, default: 'button' },
  disabled: { type: Boolean, default: false },
  size: { type: String, default: '' },
  loading: { type: Boolean,default: false },
  to: { type: [String, Object], default: null },
  href: { type: String, default: null },
  variant: { type: String, default: '' },
});
const $attr = useAttrs();

const btnClass = computed(() => ({
  'font-sans-medium': true,
  'btn-primary': props.theme === 'primary',
  'btn-secondary': props.theme === 'secondary',
  'btn-light': props.theme === 'light',
  'btn-orange': props.theme === 'orange',
  'btn-teal': props.theme === 'teal',
  'btn-danger': props.theme === 'danger',
  'btn-success': props.theme === 'success',
  'btn-pink': props.theme === 'pink',
  'btn-accent': props.theme === 'accent',
  'btn-transparent': props.theme === 'transparent',
  'btn-small': props.size === 'small',
  'btn-loading': props.loading,
  'btn-disabled': props.disabled,
  'btn-rounded': props.variant === 'rounded',
}));

const btnComponent = computed(() => {
  if (props.to) {
    return 'router-link';
  }

  if (props.href) {
    return 'a';
  }

  return 'button';
});
const btnAttributes = computed(() => ({
  to: props.to,
  ...props.href && { href: props.href },
  type: props.type,
  disabled: props.disabled,
  ...$attr,
}));
</script>

<script>
export default {
  compatConfig: {
    MODE: 3,
  },
};
</script>
<template>
  <component
    :is="btnComponent"
    class="btn"
    :class="btnClass"
    v-bind="btnAttributes"
  >
    <span
      v-if="loading"
      class="loading-spinner"
      role="status"
      aria-hidden="true"
    />
    <slot />
  </component>
</template>

<style lang="scss">
.btn {
  @apply flex gap-2 rounded-[6px] py-[8px] px-[16px] cursor-pointer text-sm h-[36px] transition-[.2s] border-none;

  &-primary {
    @apply bg-primary text-white;

    &:hover {
      @apply bg-i-blue;
    }
  }

  &-secondary {
    @apply bg-i-purple-gray text-white;
  }

  &-light {
    @apply bg-i-light text-primary;

    &:hover {
      @apply bg-i-gray-200;
    }
  }

  &-orange {
    @apply bg-i-orange text-white;
  }

  &-teal {
    @apply bg-i-teal text-white;
  }

  &-transparent {
    @apply bg-transparent;
  }

  &-danger {
    @apply bg-i-red text-white;
  }

  &-success {
    @apply bg-i-green text-white;
  }

  &-pink {
    @apply bg-i-pink text-white;
  }

  &-accent {
    @apply bg-i-light-600 text-i-purple;
  }

  &-small {
    @apply h-[24px] text-xs px-[8px] py-[4px];

    .loading-spinner {
      &::after {
        width: 16px !important;
        height: 16px !important;;
      }
    }
  }

  &-loading {
    @apply cursor-not-allowed pointer-events-none;
  }

  &-disabled {
    @apply bg-i-gray-200 text-i-purple-gray hover:bg-i-gray-200 cursor-not-allowed pointer-events-none;
  }

  &-rounded {
    @apply rounded-full;
  }

  .loading-spinner,
  .loading-spinner:after {
    box-sizing: border-box;
  }
  .loading-spinner {
    display: inline-block;
  }
  .loading-spinner:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: loading-spinner 1.2s linear infinite;
  }
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
</style>