<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});
</script>

<template>
  <div class="grid items-center font-sans-medium text-xs mt-2 grid-flow-col auto-cols-min">
    <div
      v-for="item in props.items"
      :key="item.title"
      class="whitespace-nowrap truncate"
    >
      <router-link
        v-if="!item.disabled && item.to"
        v-tippy="item.title"
        :to="item.to"
        class="text-primary"
      >
        {{ item.title }}
      </router-link>
      <span
        v-if="!item.disabled && item.to"
        class="text-i-purple-gray-200 px-2"
      >
        /
      </span>
      <span
        v-if="item.disabled && !item.to"
        v-tippy="item.title"
        class="text-i-purple-gray-200 cursor-not-allowed"
      >
        {{ item.title }}
      </span>
    </div>
  </div>
</template>
