import sharedRoutes from './shared-routes';
export default [
  ...sharedRoutes,
  {
    path: '/',
    name: 'DashboardPage',
    component: () => import('../views/writer/DashboardPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/mulakat/:interviewID?',
    name: 'WriterInterview',
    component: () => import('../views/writer/Interview/InterviewPage.vue'),
    children: [
      {
        path: 'dil-ve-seviye-secimi',
        name: 'LanguageSelectionStep',
        component: () => import('../views/writer/Interview/InterviewLanguagePage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'soru-asamasi',
        name: 'TestStep',
        component: () => import('../views/writer/Interview/InterviewTestPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'ilgi-alani-secim-asamasi',
        name: 'InterestSelectionStep',
        component: () => import('../views/writer/Interview/InterviewInterestPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'konu-basligi-secim-asamasi',
        name: 'SubjectSelectionStep',
        component: () => import('../views/writer/Interview/InterviewSubjectPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'icerik-yazim-asamasi',
        name: 'PreparingContentStep',
        component: () => import('../views/writer/Interview/InterviewContentPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'basvurunuz-alinmistir',
        name: 'DoneStep',
        component: () => import('../views/writer/Interview/InterviewDonePage.vue'),
        meta: {
          protected: true,
        }
      },
    ],
    meta: {
      protected: true,
    }
  },
  {
    path: '/icerik-havuzu',
    name: 'ContentPool',
    component: () => import('../views/writer/ContentPool.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/icerik-havuzu/:contentID',
    name: 'ContentPoolDetail',
    component: () => import('../views/writer/ContentPoolDetail.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/iceriklerim',
    name: 'Contents',
    component: () => import('../views/writer/ContentsPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/iceriklerim/:id',
    name: 'ContentDetailPage',
    component: () => import('../views/writer/ContentDetailPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/iceriklerim/:id/teslim-et',
    name: 'ContentDetailDeliveryPage',
    component: () => import('../views/writer/ContentDetailPreviewPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/iceriklerim/:id/revize',
    name: 'ContentDetailRevisePage',
    component: () => import('../views/writer/ContentDetailPreviewPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/profilim',
    name: 'Account',
    component: () => import('../views/writer/AccountPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/bakiyem',
    name: 'Balance',
    component: () => import('../views/writer/BalancePage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/yazarlik',
    name: 'Writership',
    component: () => import('../views/writer/WritershipPage.vue'),
    meta: {
      protected: true,
    }
  },
];