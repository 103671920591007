import DiffMatchPatch from 'diff-match-patch'
import htmldiff from './htmldiff';
import HtmlDiff from 'htmldiff-js';

export const convertToArray = data => {
  if(!(data && data.blocks)) {
    return [];
  }
  return data.blocks.map((block, i) => {
    if (block.type) {
      switch (block.type) {
        case 'paragraph':
          return block.data.text
        case 'header':
          return block.data.text
        case 'quote': // Get both of text and caption or?
          break
        case 'delimiter':
          break
        case 'image':
          break
        case 'list':
          let items = ''
          block.data.items.forEach(item => {
            items += ' ' + item
          })
          return items
        // TODO: add case for table
        case 'table':
          let texts = ''
          block.data.content.forEach(item => {
            item.forEach(x => {
              texts += ' ' + x
            })
          })
          return texts
      }
    }
  })
}

export const convertToHTML = data => {
  let html = ''
  if(!(data && data.blocks)) {
    return html;
  }
  data.blocks.forEach((block, i) => {
    switch (block.type) {
      case 'table':
        let tr_item = ''
        block.data.content.forEach(element => {
          let td_item = ''
          element.forEach((val, index) => {
            td_item += `<td>${val}</td>`
          })
          tr_item += `<tr>${td_item}</tr>`
        })
        html += `<table class="ce-table">${tr_item}</table>`
        break

      case 'paragraph':
        html += `<p class="ce-paragraph cdx-block">${block.data.text}</p>`
        break

      case 'inlineImage':
        html += `<img class="ce-image" width="100%" height="100%" style="margin-bottom: 12px" src='${block.data.url}' alt='${block.data.caption}'/>`
        break

      case 'header':
        html += `<h${block.data.level} class='ce-header'>${block.data.text} </h${block.data.level}>`
        break

      case 'list':
        let style = block.data.style === 'unordered' ? 'ul' : 'ol'
        let list = block.data.items
          .map(i => `<li class="cdx-list__item"> ${i} </li>`)
          .reduce((a, c) => a + c, '')

        html += `<${style} class="cdx-block cdx-list cdx-list--${
          style === 'ul' ? 'unordered' : 'ordered'
        }"> ${list} </${style}>`
        break

      case 'delimiter':
        html += '<br />'
        break

      case 'quote':
        html += `<figure class="quote ${block.data.alignment}">
                  <blockquote>
                  ${block.data.text}
                  </blockquote>
                  <figcaption>
                    <cite>- ${block.data.caption}</cite>  
                  </figcaption>
                 </figure>`
        break
    }
  })
  return html
}

export const getWordCountFromArray = data => {
  if (data.blocks) {
    let wordCount = 0
    let convertedData = convertToArray(data)

    const filteredData = convertedData.map(str => {
      const cleanString = clearString(str)
      return cleanString
    })

    let countArr = filteredData.map(str => {
      if (str) return str.split(' ')
    })

    countArr.forEach(arr => {
      if (arr) wordCount += arr.length
    })
    return wordCount
  }
}

export const clearString = str => {
  if (str) {
    const HTML_REGEX = /<(.|\n)*?>/g
    // Replace &nbsp; string with empty string
    if (str.indexOf('&nbsp;') > -1) {
      str = str.replace(/&nbsp;/g, ' ').trim()
    }
    // Remove whitespace if character at 0 === whitespace (for list block)
    else if (str.charAt(0) === ' ') {
      str = str.replace(' ', '').trim()
    }

    // Remove all html tags in string
    str = str.replace(HTML_REGEX, '')

    // Replace whitespaces with 'one' space if space count more than one
    str = str.replace(/\s{2,}/g, ' ')

    return str
  }
}

export const convertArrayToString = data => {
  const arr = convertToArray(data)

  return clearString(arr.join(' '))
}

export const getAllRevises = data => {
  let revises = []
  let match
  if(!(data && data.blocks)) {
    return revises;
  }
  data.blocks.forEach(block => {
    
    switch (block.type) {
      case 'paragraph':
        match = checkIfRevisionRegexMatches(block.data.text)
        if (match) {
          revises.push(match)
        }
        break
      case 'list':
        block.data.items.forEach(item => {
          match = checkIfRevisionRegexMatches(item)
          if (match) {
            revises.push(match)
          }
        })
        break
      case 'header':
        match = checkIfRevisionRegexMatches(block.data.text)
        if (match) {
          revises.push(match)
        }
        break
      case 'quote':
        let matchText = checkIfRevisionRegexMatches(block.data.text)

        let matchCaption = checkIfRevisionRegexMatches(block.data.caption)

        if (matchText) {
          revises.push(matchText)
        }

        if (matchCaption) {
          revises.push(matchCaption)
        }

        break

      default:
        break
    }
  })

  return revises
}

export const checkIfRevisionRegexMatches = text => {
  const revisionRegex = /revision=(.*?)".*?"/gm

  let matches = text.match(revisionRegex)

  if (matches) {
    return matches.map(match => {
      return match.split('=')[1]
    })
  }
}

export const modifyEditorData = content => {
  if (content) {
    const wordCount = getWordCountFromArray(content)
    const contentHTML = convertToHTML(content)
    const contentAsString = convertArrayToString(content)

    return {
      content,
      wordCount,
      contentHTML,
      contentAsString,
    }
  }
}

export const delete_marks = content => {
  let new_content = {
    blocks: [],
    time: null,
    version: null,
  }
  if (Object.keys(content).length > 0 && content.blocks) {
    new_content.time = content.time
    new_content.version = content.version

    content.blocks.forEach(element => {
      new_content.blocks.push(element)
    })

    new_content.blocks.forEach(element => {
      if (element.type === 'paragraph' || element.type === 'header') {
        element.data.text = element.data.text.replace(
          /(<mark[^>]*>|<\/mark>)/gi,
          ''
        )
      }
      if (element.type === 'table') {
        if (element.data.content) {
          element.data.content.forEach((column, index, array) => {
            column.forEach((row, index, rowArray) => {
              row = row.replace(/(<mark[^>]*>|<\/mark>)/gi, '')
              rowArray[index] = row
            })
          })
        }
      }

      if (element.type === 'list') {
        element.data.items.forEach((list, index, listArray) => {
          list = list.replace(/(<mark[^>]*>|<\/mark>)/gi, '')
          listArray[index] = list
        })
      }
    })

    return new_content
  } else return {}
}

export const color_differences = (first_content, second_content) => {
  let html = ''
  let html_first_content = convertToHTML(first_content)
  let html_second_content = convertToHTML(second_content)

  html = htmldiff(html_second_content, html_first_content)
  return html
}

export const diff_match_patch = (first_content, second_content) => {
  let html = ''

  const dmp = new DiffMatchPatch();
  
  const diff = dmp.diff_main(first_content.replace(/<\/? *mark[^>]*>/gm, ""), second_content);
  
  dmp.diff_cleanupSemantic(diff)

  diff.forEach(element => {
    if(element[0] === 0) {
      html += element[1]
    }
    if(element[0] === -1) {
      html += `<del>${element[1]}</del>`
    }
    if(element[0] === 1) {
      html += `<ins>${element[1]}</ins>`
    }
  })
  
  return html.replace(/<\/? *mark[^>]*>/gm, "").replace(/<\/? *sup[^>]*>/gm, "")
  // return html.replace(/<del><mark ..*><\/mark><\/del>/gm, "").replace(/<ins><mark ..*><\/mark><\/ins>/gm, "")
}

export const convertToHTMLForDocx = data => {
  let html = ''
  if(!(data && data.blocks)) {
    return html;
  }
  data.blocks.forEach((block, i) => {
    switch (block.type) {
      case 'table':
        let tr_item = ''
        block.data.content.forEach(element => {
          let td_item = ''
          element.forEach((val, index) => {
            td_item += `<td>${val}</td>`
          })
          tr_item += `<tr>${td_item}</tr>`
        })
        html += `<table>${tr_item}</table>`
        break

      case 'paragraph':
        html += `<p>${block.data.text}</p>`
        break

      case 'header':
        html += `<h${block.data.level}>${block.data.text} </h${block.data.level}>`
        break

      case 'list':
        let style = block.data.style === 'unordered' ? 'ul' : 'ol'
        let list = block.data.items
          .map(i => `<li> ${i} </li>`)
          .reduce((a, c) => a + c, '')

        html += `<${style}"> ${list} </${style}>`
        break

      case 'delimiter':
        html += '<br />'
        break

      case 'quote':
        html += `<figure>
                  <blockquote>
                  ${block.data.text}
                  </blockquote>
                  <figcaption>
                    <cite>- ${block.data.caption}</cite>  
                  </figcaption>
                 </figure>`
        break
    }
  })
  return html
}

export const convertToHTMLForHTML = data => {
  let html = ''
  if(!(data && data.blocks)) {
    return html;
  }
  data.blocks.forEach((block, i) => {
    switch (block.type) {
      case 'table':
        let tr_item = ''
        block.data.content.forEach(element => {
          let td_item = ''
          element.forEach((val, index) => {
            td_item += `<td>${val}</td>`
          })
          tr_item += `<tr>${td_item}</tr>`
        })
        html += `<table>${tr_item}</table>`
        break

      case 'paragraph':
        html += `<p>${block.data.text}</p>`
        break

      case 'inlineImage':
        html += `<img src='${block.data.url}' width="100%" alt='${block.data.caption}'/>`
        break

      case 'header':
        html += `<h${block.data.level}>${block.data.text} </h${block.data.level}>`
        break

      case 'list':
        let style = block.data.style === 'unordered' ? 'ul' : 'ol'
        let list = block.data.items
          .map(i => `<li> ${i} </li>`)
          .reduce((a, c) => a + c, '')

        html += `<${style}> ${list} </${style}>`
        break

      case 'delimiter':
        html += '<br />'
        break

      case 'quote':
        html += `<figure>
                  <blockquote>
                  ${block.data.text}
                  </blockquote>
                  <figcaption>
                    <cite>- ${block.data.caption}</cite>  
                  </figcaption>
                 </figure>`
        break
    }
  })
  return html
}

export const fetchImages = data => {
  let html = ''
  if(!(data && data.blocks)) {
    return html;
  }
  data.blocks.forEach((block, i) => {
    switch (block.type) {
      case 'inlineImage':
        html += `<img style="transform:scale(0.1);" src='${block.data.url} alt='${block.data.caption}'/>`
        break
    }
  })
  return html
}
