<template>
  <div :id="id" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    classes: {
      type: String,
    },
    id: {
      type: String,
    },
  },
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/main'

/** Box **/
div.box
  padding: 20px
  margin-bottom: 20px
  /** Box Header **/
  div.box-header
    margin-bottom: 25px
    div.box-title
      font-family: $dm
      font-size: 16px
</style>
