const projectStatusEnums = {
  continues: [2, 6],
  drafts: [1],
  done: [3, 4, 5],
  canceled: [4, 5],
}

Object.freeze(projectStatusEnums)

export default projectStatusEnums
