import { defineStore } from 'pinia';
import { api } from '@/plugins/axios';
import { ref } from 'vue';

export const useUiStore = defineStore('ui', () =>{
  const activeModals = ref([]);
  const showModal = (modalName) => {
    if(!activeModals.value.includes(modalName)) {
      activeModals.value.push(modalName);
    }
  };

  const hideModal = (modalName) => {
    activeModals.value = activeModals.value.filter((name) => name !== modalName);
  };

  return {
    activeModals,
    showModal,
    hideModal,
  };
});