import { api } from '@/plugins/axios';

const contentPool = {
  state: {
    contentPoolItems: {
      special: [],
      getable: [],
      others: [],
      all: [],
    },
    filterData: {
      languages: [],
      interests: [],
      writerLevels: [],
    },
  },

  getters: {
    contentPoolItems(state) {
      return state.contentPoolItems;
    },

    filterData(state) {
      return state.filterData;
    },
  },

  actions: {
    async getContentPoolItems(context, payload) {
      const [
        { data: contentPool },
        { data: writerInterests },
        { data: userProfile },
      ] = await Promise.all([
        api.get('/content/pool'),
        api.get('/profile/interest'),
        api.get('/profile/w/me'),
      ]);

      const contentPoolItems = {
        special: [],
        getable: [],
        others: [],
        all: [],
      };

      function getContents() {
        contentPool.forEach(content => {
          userProfile.main.rel_user_writer_level.forEach(x => {
            writerInterests.forEach(({ interest }) => {
              if (
                content.language.id === x.language.id &&
                content.writer_level.id === x.writer_level.id &&
                content.interest.id === interest.id
              ) {
                contentPoolItems.special.push({
                  ...content,
                  status: {
                    string: 'Bana özel',
                    bgColor: 'bg-teal-light',
                    color: 'text-teal',
                  },
                });
              } else if (
                content.language.id === x.language.id &&
                content.writer_level.id === x.writer_level.id
              ) {
                contentPoolItems.getable.push({
                  ...content,
                  status: {
                    string: 'Alabileceğim içerik',
                    bgColor: 'bg-green-light',
                    color: 'text-green',
                  },
                });
              } else if (
                (content.language.id !== x.language.id ||
                  content.interest.id !== interest.id) ||
                content.writer_level.id !== x.writer_level.id
              ) {
                contentPoolItems.others.push(content);
              }
            });
          });
        });

        // Get unique items in getable contents
        contentPoolItems.getable = contentPoolItems.getable.filter(
          (thing, index, self) =>
            self.findIndex(t => t.id === thing.id) === index
        );

        // contentPoolItems.getable = [
        //   ...new Set(contentPoolItems.getable.map(content => content)),
        // ]

        // Get unique items in others
        contentPoolItems.others = [
          ...new Set(contentPoolItems.others.map(content => content)),
        ];

        contentPoolItems.all = [
          ...contentPoolItems.special,
          ...contentPoolItems.getable,
          ...contentPoolItems.others,
        ].filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
        
      }

      getContents();

      context.commit('getContentPoolItemsHandler', contentPoolItems);
    },

    getLanguages(context, payload) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/language`)
        .then(response => {
          context.commit('getLanguagesHandler', response.data);
        });
    },

    getInterests(context, payload) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/interest`)
        .then(response => {
          context.commit('getInterestsHandler', response.data);
        });
    },

    getWriterLevels(context, payload) {
      return api
        .get(`${import.meta.env.VITE_API_URL}/info/writerlevel`)
        .then(response => {
          context.commit('getWriterLevelsHandler', response.data);
        });
    },
  },

  mutations: {
    getContentPoolItemsHandler(state, payload) {
      state.contentPoolItems = payload;
    },

    getLanguagesHandler(state, payload) {
      state.filterData.languages = payload;
    },

    getInterestsHandler(state, payload) {
      state.filterData.interests = payload;
    },

    getWriterLevelsHandler(state, payload) {
      state.filterData.writerLevels = payload;
    },
  },
};

export default contentPool;
