<script>
import { mapGetters, mapActions } from 'vuex';
import VBreadcrumb from '@/components/atoms/VBreadcrumb.vue';
import VSidebarMenu from '@/components/organisms/VSidebarMenu.vue';
import { useUserStore } from '@/stores/user';
import { computed, provide, ref } from 'vue';

export default {
  components: {
    VBreadcrumb,
    VSidebarMenu,
  },
  setup() {
    const userStore = useUserStore();
    const pageTitle = ref(null);
    const pageSubtitle = ref(null);
    const isPageFullscreen = ref(false);
    const updatePageFullscreen = (enabled) => {
      isPageFullscreen.value = enabled;
    };
    const updatePageTitle = (title) => {
      pageTitle.value = title;
    };
    const updatePageSubtitle = (subtitle) => {
      pageSubtitle.value = subtitle;
    };
    const breadcrumbs = ref([]);
    const updateBreadcrumbs = (data) => {
      breadcrumbs.value = data;
    };
    const isBreadcrumbVisible = ref(true);
    const updateBreadcrumbVisibility = (visibility) => {
      isBreadcrumbVisible.value = visibility;
    };
    provide('layoutOptions', {
      pageTitle,
      updatePageTitle,
      updatePageSubtitle,
      pageSubtitle,
      isPageFullscreen,
      updatePageFullscreen,
      breadcrumbs,
      isBreadcrumbVisible,
      updateBreadcrumbs,
      updateBreadcrumbVisibility,
    });

    const currentMode = import.meta.env.MODE;
    const writerMenuItems = computed(() => [
      {
        icon: 'icon-home',
        text: 'Anasayfa',
        to: '/',
      },
      {
        icon: 'icon-file',
        text: 'İçerikler',
        to: '/iceriklerim',
        userType: 'not-candidate'
      },
      {
        icon: 'icon-pool',
        text: 'İçerik Havuzu',
        to: '/icerik-havuzu',
        userType: 'not-candidate'
      },
      {
        icon: 'icon-lira-circle',
        text: 'Bakiyem',
        to: '/bakiyem',
        userType: 'not-candidate'
      },
      {
        icon: 'icon-message-in',
        text: 'Mesajlar',
        to: '/mesajlar',
      },
      {
        icon: 'icon-blog',
        text: 'Yazarlık',
        to: '/yazarlik',
      },
    ].filter((item) => !userStore.userInfo?.isCandidateWriter || item.userType !== 'not-candidate'));
    const publisherMenuItems = [
      {
        icon: 'icon-home',
        text: 'Anasayfa',
        to: '/',
      },
      {
        icon: 'icon-folder',
        text: 'Projeler',
        to: '/projelerim',
      },
      {
        icon: 'icon-group',
        text: 'Kullanıcılar',
        to: '/kullanicilar',
      },
      {
        icon: 'icon-lira-circle',
        text: 'Bakiye ve Ödemeler',
        to: '/bakiye-ve-odemelerim',
      },
      {
        icon: 'icon-message-in',
        text: 'Mesajlar',
        to: '/mesajlar',
      },
    ];
    const menuItems = computed(() => (
      currentMode === 'writer' ? writerMenuItems.value : publisherMenuItems
    ));

    const publisherAction = {
      text: 'Proje Oluştur',
      to: { name: 'ProductSelectionPage' },
    };
    const actionButton = computed(() => (
      currentMode === 'publisher' ? publisherAction : null
    ));
    const isAppLoading = ref(false);

    return {
      userStore,
      pageTitle,
      breadcrumbs,
      isBreadcrumbVisible,
      pageSubtitle,
      isPageFullscreen,
      menuItems,
      actionButton,
      isAppLoading,
    };
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'profileData',
    ]),
  },
  mounted() {
    this.getUserProfile();
    this.getProfileData();
  },
  methods: {
    ...mapActions([
      'getUserProfile',
      'setProfileData',
    ]),
    handleOnNotificationClicked() {
      this.getProfileData();
    },
    getProfileData() {
      const appMode = import.meta.env.MODE;
      const urlHash = {
        writer: 'profile/w/me',
        publisher: 'profile/me',
      };
      this.isAppLoading = true;
      this.$api.get(urlHash[appMode] || urlHash.publisher).then((response) => {
        this.setProfileData(response.data);

        this.userStore.setUserInfo(response.data.main);
      }).finally(() => {
        this.isAppLoading = false;
      });
    },
  },
};
</script>

<template>
  <div
    v-if="!isAppLoading"
    id="general-container"
    class="default-layout container-fluid"
  >
    <v-sidebar-menu
      v-if="!isPageFullscreen"
      :menu-items="menuItems"
      :action-button="actionButton"
    />
    <main
      id="main"
      :class="{ fullscreen: isPageFullscreen }"
    >
      <the-page-header
        v-if="!isPageFullscreen"
        :profile-data="profileData"
        :title="pageTitle"
        is-publisher
        @notification-clicked="handleOnNotificationClicked"
      >
        <template
          v-if="isBreadcrumbVisible"
          #breadcrumb
        >
          <v-breadcrumb
            :items="breadcrumbs"
          />
        </template>
        <template
          v-else
          #subtitle
        >
          <span
            v-if="pageSubtitle"
            class="text-gray page-subtitle f-dmsans f-500"
          >
            {{ pageSubtitle }}
          </span>
        </template>
      </the-page-header>
      <router-view
        v-if="userProfile?.id"
        :key="$route.fullPath"
        v-slot="{ Component }"
      >
        <component
          :is="Component"
          :key="$route.fullPath"
        />
      </router-view>
      <notifications
        position="bottom center"
        :width="390"
        group="error"
        ignore-duplicates
        classes="alert-error"
      />
      <notifications
        position="bottom center"
        :width="390"
        group="success"
        ignore-duplicates
        classes="alert-success"
      />
      <notifications
        position="bottom center"
        :width="390"
        group="warning"
        ignore-duplicates
        classes="alert-warning"
      />
    </main>
  </div>
</template>

<style lang="sass">
@import '/fonts/icomoon/icomoon.css'

.breadcrumb
  margin-bottom: 30px
  font-family: $dm
  padding: 0
  margin: 0
  font-size: 0
  background: none
  li.breadcrumb-item
    display: inline-block
    font-size: 12px
    color: $primary
    &:last-child
      &:after
        display: none
    &:after
      margin: 0px 5px
      color: #9795A6
    &:hover
      color: #9795A6
    span.router-link-exact-active
      color: #9795A6
    a.router-link-exact-active
      color: #9795A6!important
    &:last-child
      a.router-link-active
        color: #9795A6!important
#main
  flex: 0 0 100%
  -ms-flex: 0 0 100%
  padding: 30px
main#main.fullscreen
  padding: 0 !important
  max-width: 100%!important
  height: 100vh!important
  margin-left: 0!important
  width: 100%
.vue-notification-group
  z-index: 998 // Modals doesn't work if this would be higher than this value
  .vue-notification-wrapper
    margin-bottom: 24px
    .vue-notification-template
      height: auto
      border-radius: 8px
      margin: 8px
      font-size: 14px
      display: flex
      align-items: center
      .notification-content
        padding-top: 6px
        padding-bottom: 6px
    .alert-error
      background: $red-light
      color: #3D4166

      .notification-title
      .notification-content
        margin-left: 14px
      &.error

    .alert-success
      background: $green-light
      color: #3D4166

      .notification-title
      .notification-content
        margin-left: 14px
      &.success

    .alert-warning
      background: $orange-light
      color: #3D4166

      .notification-title
      .notification-content
        margin-left: 14px
      &.warn
  .custom-tooltip
    position: relative
    display: inline-block
    vertical-align: middle
    left: 5px
    top: 1px
  .tooltiptext
    visibility: hidden
    opacity: 0
    background-color: $gray
    color: #fff
    text-align: center
    padding: 0 15px
    border-radius: 6px
    position: absolute
    z-index: 1
    bottom: 110%
    min-width: 100px
    left: calc(50% - 50px)
    transition: opacity 0.3s
    font-family: $dm
    font-size: 12px
    color: #fff
    &:after
      content: ""
      position: absolute
      top: 100%
      left: 50%
      margin-left: -5px
      border-width: 5px
      border-style: solid
      border-color: $gray transparent transparent transparent
  &:hover
    .tooltiptext
      visibility: visible
      opacity: 1

.bg-light2
  background-color: $light2
.fade-enter-active, .fade-leave-active
  transition: opacity .1s

.fade-enter, .fade-leave-to
  opacity: 0.5
.vue-tooltip
  background-color: #868AB2 !important
  font-family: $dm
  font-size: 12px
  color: #fff
  box-shadow: none !important
  padding: 0px 10px
  border-radius: 6px
  .tooltip-arrow
    border-color: #868AB2 !important
.page-subtitle
  font-size: 12px
  color: $gray
  font-weight: 500
.swap-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  padding: 0!important
  margin: 0!important
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .host-guest-swap
    display: flex
    align-items: center
    width: 100%
    justify-content: center
    margin-top: 32px
  .host, .guest
    display: flex
    align-items: center
    justify-content: center
    color: white
    border: 1px solid white
  .host
    width: 48px
    height: 48px
    border-radius: 50px
    animation: swap-host 4s infinite

  .guest
    width: 48px
    height: 48px
    margin-right: 10%
    border-radius: 50px
    animation: swap-guest 4s infinite
@keyframes swap-guest
  0%
    transform: translateX(0)
  100%
    transform: translateX(98.5px)
@keyframes swap-host
  0%
    transform: translateX(0)
  100%
    transform: translateX(-98.5px)
input::placeholder
    font-family: 'DM Sans', sans-serif
    font-weight: 400

select
  font-weight: 400
  font-family: 'DM Sans', sans-serif !important
  font-size: 13px !important
  transition: .2s
  cursor: pointer
  background-color: white
  &:hover
        border-color: #afacc2 !important
.primary-hover
  transition: .2s
  &:hover
   color: $primary  !important
</style>
