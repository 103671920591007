import { createStore } from 'vuex';
import Cookies from 'js-cookie';
import { api } from '@/plugins/axios';

// Modules
import newProject from './publisher-modules/new-project';
import projects from './publisher-modules/projects';
import projectDetail from './publisher-modules/project-detail';
import myAccount from './publisher-modules/my-account';
import balanceAndPayments from './publisher-modules/balance-and-payments';
import messages from './publisher-modules/messages';
import users from './publisher-modules/users';
import global from './publisher-modules/global';

import writerInterviewWriter from './writer-modules/writer-interview';
import contentPoolWriter from './writer-modules/content-pool';
import balanceWriter from './writer-modules/balance';
import dashboardWriter from './writer-modules/dashboard';
import messagesWriter from './writer-modules/messages';
import writershipWriter from './writer-modules/writership';
import contentsWriter from './writer-modules/contents';
import contentDetailWriter from './writer-modules/content_detail';
import globalWriter from './writer-modules/global';

const mode = import.meta.env.MODE || 'writer';

const modules = {
  writer: {
    writerInterviewWriter,
    contentPoolWriter,
    balanceWriter,
    dashboardWriter,
    messagesWriter,
    writershipWriter,
    contentsWriter,
    contentDetailWriter,
    globalWriter,
  },
  publisher: {
    newProject,
    projects,
    projectDetail,
    myAccount,
    balanceAndPayments,
    messages,
    users,
    global,
  },
};


export const store = createStore({
  state: {
    token: null,
    userProfile: null,
    isFullscreen: false,
    profileData: null,
  },

  actions: {
    setToken({ commit }, payload) {
      Cookies.set('icerik_publisher_partnership', payload);
      commit('setTokenHandler', payload);
    },
    setIsFullscreen({ commit }, payload) {
      commit('setIsFullscreenHandler', payload);
    },
    setProfileData({ commit }, payload) {
      commit('setProfileDataHandler', payload);
    },
    getAuthToken(context, payload) {
      let token;
      let guestToken = Cookies.get(
        'icerik_publisher_partnership'
      );
      if (guestToken) {
        token = guestToken;
      } else {
        token = Cookies.get('icerik_publisher');
      }

      context.commit('getAuthTokenHandler', token);
    },

    removeAuthToken(context, payload) {
      context.commit('removeAuthTokenHandler');
    },

    getUserProfile(context, payload) {
      return api.get('profile').then(response => {
        context.commit('setUserProfileHandler', response.data);
      });
    },

    setUserProfile(context, payload) {
      context.commit('setUserProfileHandler', payload);
    },
  },

  getters: {
    token(state) {
      return state.token;
    },

    userProfile(state) {
      return state.userProfile;
    },

    isFullscreen(state) {
      return state.isFullscreen;
    },

    profileData(state) {
      return state.profileData;
    },
  },

  mutations: {
    setTokenHandler(state, payload) {
      state.token = payload;
    },
    setProfileDataHandler(state, payload) {
      state.profileData = payload;
    },
    getAuthTokenHandler(state, payload) {
      state.token = payload;
    },
    removeAuthTokenHandler(state, payload) {
      state.token = null;
    },

    setUserProfileHandler(state, payload) {
      state.userProfile = payload;
    },
    setIsFullscreenHandler(state, payload) {
      state.isFullscreen = payload;
    },
  },

  modules: {
    ...modules[mode],
  },
});
