const dashboard = {
  state: {
    userTodos: [],
  },

  getters: {},

  actions: {
    setUserTodos({ commit }, payload) {},
  },

  mutations: {
    setUserTodosHandler(state, payload) {
      state.userTodos = payload
    },
  },
}

export default dashboard
