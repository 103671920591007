<template>
  <a
    class="box-item brd-16 h-100"
    :class="
      `${box.textColor} ${isActive ? box.selectedBgColor : box.bgColor} ${
        box.hoverColor
      }`
    "
  >
    <div
      v-if="box.icon"
      class="icon brd-16 bg-white"
      :class="`${box.textColor}`"
    >
      <i :class="box.icon"></i>
    </div>
    <div class="content" :class="{ 'pl-0': !box.icon, ' h-100 w-100 d-flex flex-column justify-content-between' : from_icerikler_page }">
      <span class="count f-db f-700" :style="customStyle">{{ box.count }}</span>
      <span class="name f-dm"
        >{{ box.text }}
        <i
          v-tippy="box.infoText"
          v-if="box.hasInfo"
          class="icon-info"
        ></i>
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'IcerikStatusBoxesInfoBox',
  props: {
    box: {
      type: Object,
      default() {
        return {}
      },
    },
    isActive: {
      type: Boolean,
    },
    selectedStatusBox: {
      type: Number,
    },
    from_icerikler_page : {
      type : Boolean,
      default : false
    },
    customStyle: {
      type : String,
      default : ''
    }
  },
  mounted() {},
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/main'

.box-item
  cursor: pointer
  display: block
  padding: 15px
  transition: .2s
  .icon
    height: 56px
    width: 48px
    display: inline-block
    vertical-align: middle
    font-size: 24px
    text-align: center
    line-height: 56px
  .content
    width: calc(100% - 48px)
    display: inline-block
    vertical-align: middle
    font-size: 14px
    padding-left: 15px
    .count
      display: block
      font-size: 28px
      line-height: 28px
    .name
      font-size: 12px
      line-height: 12px
      .icon-info
        margin-top: 8px
        margin-left: 5px
        font-size: 16px
.active
  div.icon
</style>
