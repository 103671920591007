import contentStatusEnums from '@/enums/contentStatusEnums';

const contents = {
  state: {
    contents: {
      continues: [],
      editorControl: [],
      editorRevise: [],
      publisherControl: [],
      publisherRevise: [],
      done: [],
    },
  },

  getters: {
    contents(state) {
      return state.contents;
    },
  },

  actions: {
    setContentsData({ commit }, payload) {
      const contents = {
        continues: [],
        editorControl: [],
        editorRevise: [],
        publisherControl: [],
        publisherRevise: [],
        done: [],
      };

      payload.forEach(async content => {
        let contentSubmissionId = content.last_submission.content_submission_status_id;
        if (contentSubmissionId === 100) {
          contentSubmissionId = content.last_submission_before_copyscape.content_submission_status_id;
        }
        if (contentStatusEnums.continues.includes(contentSubmissionId)) {
          contents.continues.push(content);
        } else if (
          contentStatusEnums.editorControl.includes(contentSubmissionId)
        ) {
          contents.editorControl.push(content);
        } else if (
          contentStatusEnums.editorRevise.includes(contentSubmissionId)
        ) {
          contents.editorRevise.push(content);
        } else if (
          contentStatusEnums.publisherControl.includes(contentSubmissionId)
        ) {
          contents.publisherControl.push(content);
        } else if (
          contentStatusEnums.publisherRevise.includes(contentSubmissionId)
        ) {
          contents.publisherRevise.push(content);
        } else if (contentStatusEnums.done.includes(contentSubmissionId)) {
          contents.done.push(content);
        }
      });

      commit('setContentsDataHandler', contents);
    },

    setFinishedContentsData({ commit }, payload) {
      this.set;
    },
  },

  mutations: {
    setContentsDataHandler(state, payload) {
      state.contents = payload;
    },
  },
};

export default contents;
