<template>
  <div
    v-if="profileData"
    class="page-header"
  >
    <div class="flex">
      <div class="w-2/3">
        <h1 class="page-title f-dmsans">
          <span
            v-if="title"
            class="text-capitalize"
          >
            {{ title }}
          </span>
        </h1>
        <slot name="breadcrumb" />
        <slot name="subtitle" />
      </div>
      <div class="w-1/3">
        <div class="header-blocks">
          <page-header-notification
            :notifications="notifications"
            :is-admin="isAdmin"
            @notification-clicked="$emit('notification-clicked')"
          />
          <div
            v-if="
              (profileData.main && profileData.main.user_type.id === 2) ||
                profileData.main.user_type.id === 3
            "
            class="text-purple d-flex align-items-center bg-white py-2 px-3 balance-box"
          >
            <span class="f-dmsans f-400">₺</span>
            <span class="f-dmsans f-400">
              {{ profileData.main.balance_float }}
            </span>
          </div>
          <page-header-menu
            :user-data="userData"
            :is-writer="isWriter"
            :is-admin="isAdmin"
            :is-publisher="isPublisher"
            @show-swap-screen="$emit('show-swap-screen', $event)"
            @upgrade-account-clicked="$emit('upgradeAccountClicked')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderNotification from './PageHeaderNotification';
import PageHeaderMenu from './PageHeaderMenu';

export default {
  name: 'Header',
  components: {
    PageHeaderNotification,
    PageHeaderMenu,
  },
  props: {
    title: {
      type: String,
      // required: true
    },
    breadcrumbs: {
      type: Array,
      // required: true
    },
    profileData: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isWriter: {
      type: Boolean,
      default: false,
    },
    isPublisher: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notifications() {
      if (this.profileData) {
        return {
          unread_messages: this.profileData.main.unread_messages,
          todo: this.profileData.main.todo,
        };
      }
    },

    userData() {
      if (this.profileData) {
        return {
          email: this.profileData.main.email,
          full_name: this.profileData.main.full_name,
          name: this.profileData.main.name,
          last_name: this.profileData.main.last_name,
          user_type: this.profileData.main.user_type,
          active: this.profileData.main.active,
          partnership_list: this.profileData.main.partnership_list_by_guest,
          partnership: this.profileData.partnership,
        };
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import '@/assets/sass/main'
.header-blocks
  display: flex
  align-items: center
  justify-content: flex-end
div.balance-box
  border-radius: 23px
  margin-right: 16px
div.page-header
  margin-bottom: 30px
  .page-title
    font-size: 24px
    line-height: 24px
    font-weight: bold
  nav.page-breadcrumb
    font-family: $dm
    ul
      padding: 0
      margin: 0
      font-size: 0
      li
        display: inline-block
        font-size: 12px
        color: $primary
        &:last-child
          &:after
            display: none
        &:after
          content: "/"
          margin: 0px 5px
          color: #9795A6
        &:hover
          color: #9795A6
      li.active
        color: #9795A6
</style>
