const users = {
  state: {
    users: null,
    showSwapScreen: false,
  },

  getters: {
    users(state) {
      return state.users
    },

    showSwapScreen(state) {
      return state.showSwapScreen
    },
  },

  actions: {
    setUsers(context, payload) {
      context.commit('setUsersHandler', payload)
    },

    pushToUsers({ commit }, payload) {
      commit('pushToUsersHandler', payload)
    },

    deleteUser({ commit }, payload) {
      commit('deleteUserHandler', payload)
    },

    updateUserPermission({ commit }, payload) {
      commit('updateUserPermissionHandler', payload)
    },

    setShowSwapScreen({ commit }, payload) {
      commit('setShowSwapScreenHandler', payload)
    },
  },

  mutations: {
    setUsersHandler(state, payload) {
      state.users = payload
    },

    pushToUsersHandler(state, payload) {
      state.users.push(payload)
    },

    deleteUserHandler(state, userIdx) {
      state.users.splice(userIdx, 1)
    },

    setShowSwapScreenHandler(state, payload) {
      state.showSwapScreen = payload
    },

    updateUserPermissionHandler(state, payload) {
      // Update user
      Vue.set(state.users, payload.userIdx, {
        ...state.users[payload.userIdx],
        has_full_access: payload.permission === 'Tam Yetkili' ? true : false,
      })
    },
  },
}

export default users
