const content_detail = {
  state: {
    contentDetail: null
  },

  getters: {
    getContentDetail(state) {
      return state.contentDetailTitle;
    }
  },

  mutations: {
    setContentDetail(state, payload) {
      state.contentDetailTitle = payload;
    } 
  }
}

export default content_detail;