import { defineStore } from 'pinia';
import { ref } from 'vue';
import { api } from '@/plugins/axios';

export const useUserStore = defineStore('users', () =>{
  const userInfo = ref(null);
  const setUserInfo = (data) => {
    userInfo.value = {
      active: data.active,
      email: data.email,
      isProfileComplete: data.check_profile_completed,
      name: data.name,
      type: {
        id: data.user_type.id,
        name: data.user_type.name,
      },
      todo: data.todo,
      interviewTodo: data.interview_todo,
      fullName: data.full_name,
      surname: data.last_name,
      id: data.id,
      score: data.score_info,
      writerLevel: data.rel_user_writer_level,
      partnerships: data.partnership_list_by_guest,
      balance: data.balance_float,
      isCandidateWriter: !data?.rel_user_writer_level?.length,
      invitationCount: data.remained_invitation_count,
    };
  };
  const fetchUserInfo = async () => {
    const appMode = import.meta.env.MODE;
    const urlHash = {
      writer: 'profile/w/me',
      publisher: 'profile/me',
    };
    const profileUrl = urlHash[appMode];

    const { data } = await api.get(profileUrl);

    setUserInfo(data.main);
  };

  const userProfile = ref(null);
  const fetchUserProfile = async () => {
    const { data } = await api.get('profile');
  
    userProfile.value = {
      active: data.active,
      email: data.email,
      isProfileComplete: data.is_profile_complete,
      name: data.name,
      type: {
        id: data.user_type.id,
        name: data.user_type.title,
      },
      balance: data.balance_float / 100,
      birthDate: data.birthday,
      city: data.city,
      isCompany: data.company,
      companyName: data.company_name,
      country: data.country,
      district: data.district,
      fullName: data.full_name,
      iban: data.iban,
      ibanName: data.iban_name,
      id: data.id,
      surname: data.last_name,
      nationalId: data.national_id_int,
      phone: data.phone,
      postalCode: data.postal_code,
      writerLevel: data.rel_user_writer_level,
      score: data.score_info,
      createdAt: new Date(data.t_create * 1000),
      taxOffice: data.tax_administration,
      taxNumber: data.tax_no,
      website: data.web_url,
      address: data.address,
    };

    return data;
  };

  const updateUserProfile = async (data) => {
    await api.put('profile', {
      ...data,
      birthday: data.birthDate,
      company: data.isCompany,
      company_name: data.companyName,
      country: data.country,
      district: data.district,
      full_name: data.fullName,
      iban: data.iban,
      iban_name: data.ibanName,
      last_name: data.surname,
      national_id: data.nationalId,
      phone: data.phone,
      postal_code: data.postalCode,
      tax_administration: data.taxOffice,
      tax_no: data.taxNumber,
      web_url: data.website,
    });
  };

  const fetchUsersPartnerships = async () => {
    const { data } = await api.get('partnership');
    
    return data;
  };

  const swapAccount = ref(null);
  const authenticateWithSwapAccount = async () => {
    api.get(`partnership/${swapAccount.value.id}/login`, {
      mode: 'cors',
      withCredentials: true,
    });
  };

  const updateInvitedUserPermission = async ({ id, permission }) => {
    await api.put(`partnership/${id}`, {
      has_full_access: permission === 'full_access' ? true : false,
    });
  };

  const removeInvitedUser = async ({
    id,
  }) => {
    await api.delete(`partnership/${id}`);
  };

  const inviteUsers = async ({ users = [] }) => {
    const data = users.map((user) => ({
      email: user.email,
      has_full_access: user.permission === 'full_access' ? true : false,
    }));
    await api.post('partnership', data);
  };  

  const fetchInvitedUsers = async () => {
    const { data } = await api.get('interview/invitation');
    return data;
  };

  const fetchUserInterviewAvailability = async () => {
    const { data } = await api.get('interview/suitability');
    return data;
  };

  const fetchUserInterviewResults = async () => {
    const { data } = await api.get('interview');
    return data;
  };

  const postUserInvitation = (payload) => {
    return api.post('interview/invitation', {
      name_surname: payload.userFullName,
      email: payload.userEmail,
      reason: payload.invitationReason,
    });
  };

  const fetchSuitabilityData = async () => {
    const { data } = await api.get('interview/suitability');
    return data;
  };

  return {
    userInfo,
    setUserInfo,
    fetchUsersPartnerships,
    swapAccount,
    authenticateWithSwapAccount,
    updateInvitedUserPermission,
    removeInvitedUser,
    inviteUsers,
    fetchUserProfile,
    userProfile,
    fetchUserInfo,
    updateUserProfile,
    fetchInvitedUsers,
    fetchUserInterviewAvailability,
    fetchUserInterviewResults,
    postUserInvitation,
    fetchSuitabilityData,
  };
});