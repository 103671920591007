const writership = {
  state: {
    suitabilityData: null,
    allInvitations: null,
  },

  getters: {
    suitabilityData(state) {
      return state.suitabilityData
    },

    allInvitations(state) {
      return state.allInvitations
    },
  },

  actions: {
    setSuitabilityData({ commit }, payload) {
      commit('setSuitabilityDataHandler', payload)
    },

    setAllInvitations({ commit }, payload) {
      commit('setallInvitationsDataHandler', payload)
    },
  },

  mutations: {
    setSuitabilityDataHandler(state, payload) {
      state.suitabilityData = payload
    },
    setallInvitationsDataHandler(state, payload) {
      state.allInvitations = payload
    },
  },
}

export default writership
