import sharedRoutes from './shared-routes';

export default [
  ...sharedRoutes,
  {
    path: '/',
    name: 'DashboardPage',
    component: () => import('../views/publisher/DashboardPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/yeni-proje-olustur/:id?',
    name: 'CreateNewProject',
    component: () => import('../views/publisher/ProjectCreation/ProjectCreationPage.vue'),
    children: [
      {
        path: 'proje-detay',
        name: 'NewProjectDetail',
        component: () => import('../views/publisher/ProjectCreation/ProjectDetailsPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'icerik-detay',
        name: 'NewProjectContentDetail',
        component: () => import('../views/publisher/ProjectCreation/ProjectContentCreationPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'odeme',
        name: 'NewProjectPayment',
        component: () => import('../views/publisher/ProjectCreation/ProjectPaymentPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'odeme-sonuc',
        name: 'NewProjectSuccess',
        component: () => import('../views/publisher/ProjectCreation/ProjectSuccessPage.vue'),
        meta: {
          protected: true,
        }
      },
    ],
    meta: {
      protected: true,
    }
  },
  {
    path: '/urun-secimi',
    name: 'ProductSelectionPage',
    component: () => import('../views/publisher/ProductSelectionPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/projelerim',
    name: 'ProjectsPage',
    component: () => import('../views/publisher/ProjectsPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/projelerim/:projectID',
    component: () => import('../views/publisher/ProjectDetail.vue'),
    name: 'ProjectDetail',
    meta: {
      protected: true,
    }
  },
  {
    path: '/projelerim-yeni/:id',
    component: () => import('../views/publisher/ProjectDetailsPage.vue'),
    name: 'ProjectDetailsPage',
    meta: {
      protected: true,
    }
  },
  {
    path: '/projelerim/:projectID/:id',
    name: 'ContentDetailPage',
    component: () => import('../views/publisher/ContentDetail.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/kullanicilar',
    name: 'UsersPage',
    component: () => import('../views/publisher/UsersPage.vue'),
  },
  {
    path: '/bakiye-ve-odemelerim',
    name: 'Balance',
    component: () => import('../views/publisher/Balance.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/hesabim',
    name: 'Account',
    component: () => import('../views/publisher/AccountPage.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/bakiye-yukle',
    name: 'AddBalance',
    component: () => import('../views/publisher/AddBalance.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/odeme-sonuc',
    name: 'PaymentResult',
    component: () => import('../components/create-new-project-steps/DoneStep.vue'),
    meta: {
      protected: true,
    }
  },
  {
    path: '/hesap-degisim',
    name: 'AccountSwapPage',
    component: () => import('../views/publisher/AccountSwapPage.vue'),
    meta: {
      protected: true,
      layout: 'auth',
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404Page',
    component: () => import('../views/publisher/404Page.vue'),
    meta: {
      protected: true,
    }
  },
];
