<script>
import { mapActions } from 'vuex';
import { useUserStore  } from '@/stores/user';
import { useRouter } from 'vue-router';
export default {
  props: {
    userData: {
      type: Object,
    },
    isWriter: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isPublisher: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    const loginAsPartner = (partner) => {
      userStore.swapAccount = {
        ...partner,
      };

      router.push({
        name: 'AccountSwapPage',
      });
    };

    return {
      loginAsPartner,
    };
  },
  data() {
    return {
      menuIsOpen: false,
      loginedAsPartner: false,
      loginingAsPartner: false,
    };
  },

  mounted() {
    if (localStorage.getItem('icerik_publisher_partnership')) {
      this.loginedAsPartner = true;
    }
  },
  methods: {
    ...mapActions(['setToken', 'setIsFullscreen']),
    logOut() {
      let key = '';
      if (import.meta.env.MODE === 'writer') {
        key = 'icerik_writer';
      }
      else {
        key = 'icerik_publisher';
      }
      const cookie = localStorage.getItem(key);
      const partnership_cookie = localStorage.getItem('icerik_publisher_partnership');
      const api_url = import.meta.env.VITE_API_URL;
      if (cookie || partnership_cookie) {
        fetch(`${api_url}/auth/logout`, {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie}`,
          },
          credentials: 'include',
        })
          .then((response) => {
            if (response.status === 200) {
              location.href = 'https://icerik.com';
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    loginAsMe() {
      let key = '';
      if (import.meta.env.MODE === 'writer') {
        key = 'icerik_writer';
      }
      else {
        key = 'icerik_publisher';
      }
      const cookie = localStorage.getItem(key);
      const api_url = import.meta.env.VITE_API_URL;

      fetch(`${api_url}/auth/remove_partnership_cookie`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie}`,
        },
        credentials: 'include',
      }).then((response) => {
        if (response.status === 200) {
          this.loginedAsPartner = false;
          this.$router.push({
            name: 'DashboardPage',
          });
          location.reload();
        }
      });
    },
    handleOnClick() {
      this.menuIsOpen = !this.menuIsOpen;
    },
    headerMenuClickedOutsideHandler() {
      this.menuIsOpen = false;
    },
    goToAccountSettings() {
      return {
        name: 'Account',
      };
    },
  },
  computed: {
    computedAvatarColorsByUserType() {
      if (this.isWriter) {
        return {
          bgColor: 'bg-pink-light',
          color: 'text-pink',
        };
      } else {
        return {
          bgColor: 'bg-teal-light',
          color: 'text-teal',
        };
      }
    },
  },
};
</script>

<template>
  <div
    v-if="userData"
    class="header-block user-block"
  >
    <div
      v-if="!loginedAsPartner || userData.user_type.id !== 2"
      class="user-avatar cursor-pointer"
      :class="`${computedAvatarColorsByUserType.color} ${computedAvatarColorsByUserType.bgColor}`"
      @click="handleOnClick"
    >
      {{ $helpers.userAvatarName(`${userData.name} ${userData.last_name}`) }}
      <span class="down"><i
        class="icon"
        :class="{
          'icon-chevron-up': menuIsOpen,
          'icon-chevron-down': !menuIsOpen,
        }"
      />
      </span>
    </div>

    <div
      v-else
      class="user-avatar partner"
      :class="`${computedAvatarColorsByUserType.color} ${computedAvatarColorsByUserType.bgColor}`"
      @click="handleOnClick"
    >
      {{ $helpers.userAvatarName(userData.partnership.full_name) }}
      <div
        class="self-avatar"
        :class="`${computedAvatarColorsByUserType.color} ${computedAvatarColorsByUserType.bgColor}`"
      >
        <span class="text-capitalize">
          {{
            $helpers.userAvatarName(`${userData.name} ${userData.last_name}`)
          }}
        </span>
      </div>
    </div>

    <div
      v-if="menuIsOpen && userData"
      v-click-outside="headerMenuClickedOutsideHandler"
      class="user-opener"
      :aria-expanded="menuIsOpen"
    >
      <div class="opener-header">
        <div class="name">
          {{ userData.full_name }}
        </div>
        <div class="position">
          {{ userData.user_type.title }}
        </div>
      </div>

      <div
        v-if="loginedAsPartner && userData.user_type.id === 2"
        class="accounts me"
      >
        <a
          role="button"
          class="account"
          @click="loginAsMe"
        >
          <figure class="avatar bg-orange-light text-orange">
            {{
              $helpers.userAvatarName(
                userData.partnership.name + ' ' + userData.partnership.last_name
              )
            }}
          </figure>
          <div class="user-name">
            {{
              userData.partnership.name + ' ' + userData.partnership.last_name
            }}
          </div>
        </a>
      </div>

      <div
        v-if="userData.partnership_list && userData.partnership_list.length > 0"
        class="sharing-accounts"
      >
        <div class="section-title">
          Paylaşılan hesaplar
        </div>
        <div class="accounts">
          <a
            v-for="partner in userData.partnership_list"
            :key="partner.id"
            role="button"
            class="account"
            @click="loginAsPartner(partner)"
          >
            <figure class="avatar bg-orange-light text-orange">
              {{
                $helpers.userAvatarName(
                  partner.host.name + ' ' + partner.host.last_name
                )
              }}
            </figure>
            <div class="user-name text-capitalize">
              {{ partner.host.name + ' ' + partner.host.last_name }}
            </div>
          </a>
        </div>
      </div>

      <div class="opener-menu">
        <ul>
          <li
            v-if="!isAdmin"
            @click="() => menuIsOpen = false"
          >
            <router-link
              :to="goToAccountSettings()"
            >
              Hesap ayarları
            </router-link>
          </li>
          <li v-else>
            <a
              style="cursor: pointer"
              @click="$emit('upgradeAccountClicked')"
            >Yükselt</a>
            <a
              style="cursor: pointer"
              @click="$router.push({ name: 'Settings' })"
            >Ayarlar</a>
          </li>

          <li>
            <a
              style="cursor: pointer"
              @click="logOut"
            >Çıkış</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import '@/assets/sass/main'
.user-block
  .user-avatar
    width: 48px
    height: 48px
    border-radius: 100px
    border: 2px solid #FFFFFF
    text-align: center
    font-family: $dm
    font-size: 16px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    .down
      width: 16px
      height: 16px
      border-radius: 100px
      text-align: center
      line-height: 16px
      color: #868AB2
      font-size: 14px
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      background-color: #fff
      bottom: -3px
      right: 0
      .icon
        transition: all 1s ease

  div.user-opener
    position: absolute
    right: 15px
    width: 220px
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04)
    border-radius: 6px
    background-color: #fff
    text-align: left
    z-index: 99
    div.opener-header
      padding: 15px
      border-bottom: 1px solid #F7F8FF
      div.name
        font-family: $dm
        font-size: 16px
        color: $dark
        margin-bottom: 5px
      div.position
        font-family: $dm
        font-size: 12px
        color: $gray
    div.sharing-accounts
      border-bottom: 1px solid #F7F8FF
      padding: 12px 0
      .me
        margin-bottom: 12px
      div.section-title
        margin-bottom: 12px
        font-family: $dm
        font-size: 12px
        color: $gray
        padding: 0 16px
      div.accounts
        .account
          display: block
          font-size: 0
          margin-bottom: 10px
          padding: 2px 16px
          &:last-child
            margin-bottom: 0
          &:hover
            background-color: $light
          .avatar
            width: 32px
            height: 32px
            border-radius: 100px
            text-align: center
            font-family: $dm
            font-size: 12px
            line-height: 32px
            display: inline-block
            vertical-align: middle
            margin-bottom: 0
            background-repeat: no-repeat
            background-position: 50% 50%
            background-size: cover
          .user-name
            width: calc(100% - 32px)
            display: inline-block
            vertical-align: middle
            padding-left: 10px
            font-family: $dr
            font-size: 14px
            color: $dark
    div.opener-menu
      padding-top: 8px
      padding-bottom: 8px
      ul
        padding: 0
        margin: 0
        li
          list-style: none
          a
            display: block
            padding: 0px 15px
            height: 36px
            line-height: 36px
            font-family: $dr
            font-size: 14px
            color: $gray
            &:hover
              background-color: #F7F8FF
              color: $dark
.self-avatar
  width: 28px
  height: 28px
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  right: -10px
  bottom: -10px
  user-select: none
  border-radius: 100px
  border: 2px solid #FFFFFF
  text-align: center
  line-height: 48px
  font-family: $dm
  font-size: 16px
  span
    font-size: 12px
.me
  margin-top: 12px
  margin-left: 12px
  .account
    display: flex
    align-items: center
  figure
    margin: 0
    width: 28px
    display: flex
    align-items: center
    justify-content: center
    height: 28px
    margin-right: 8px
    border-radius: 50px
    font-size: 12px
  .user-name
    font-size: 14px
</style>
