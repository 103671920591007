const writerInterview = {
  state: {
    currentStep: 0,
    interviewInfo: null
  },

  getters: {
    currentStep(state) {
      return state.currentStep;
    },

    interviewInfo(state) {
      return state.interviewInfo;
    }
  },

  actions: {
    increaseCurrentStep(context) {
      context.commit("increaseCurrentStepHandler");
    },
    setCurrentStep(context, payload) {
      context.commit("setCurrentStepHandler", payload);
    },
    setInterviewInfo(context, payload) {
      context.commit('setInterviewInfoHandler', payload)
    }
  },

  mutations: {
    increaseCurrentStepHandler(state) {
      state.currentStep++;
    },
    setCurrentStepHandler(state, payload) {
      state.currentStep = payload;
    },
    setInterviewInfoHandler(state, payload) {
      state.interviewInfo = payload;
    }
  },
};

export default writerInterview;
