<script setup>
import VModal from '@/components/atoms/VModal.vue';
import VButton from '@/components/atoms/VButton.vue';
import { useUiStore } from '@/stores/ui';
import { computed, useSlots, watch } from 'vue';

const props = defineProps({
  modalName: {
    type: String,
    default: '',
  },
});
const isOpen = defineModel('open', {
  type: Boolean,
  default: false,
});

const emit = defineEmits(['close-btn-clicked', 'close', 'open']);
const slots = useSlots();
const uiStore = useUiStore();
const open = computed(() => uiStore.activeModals.includes(props.modalName) || isOpen.value);

const hasSlot = (name) => !!slots[name];

const handleToggleModal = (value) => {  
  if (value) {
    emit('open');
  } else {
    emit('close');
    isOpen.value = false;
  }
};
const handleCloseClick = () => {
  handleToggleModal(false);
  emit('close-btn-clicked');
};

watch(open, (value) => {
  if (value) {
    emit('open');
  } else {
    emit('close');
  }
});
</script>

<template>
  <v-modal
    :open="open"
    :name="modalName"
    :header="false"
    @update:open="handleToggleModal"
  >
    <div
      v-if="hasSlot('title-content')"
      class="flex justify-between items-center"
    >
      <div class="text-dark text-base font-bold">
        <slot name="title-content" />
      </div>
      <v-button
        theme="transparent"
        @click.prevent="handleCloseClick"
      >
        <i
          aria-label="Kapat"
          class="icon-close"
        />
      </v-button>
    </div>
    <slot />
    <section
      v-if="hasSlot('body-content')"
      class="border-none my-4"
    >
      <slot name="body-content" />
    </section>
    <section
      v-if="hasSlot('form-content')"
    >
      <slot name="form-content" />
    </section>
    <footer
      v-if="hasSlot('footer-content')"
      class="border-none"
    >
      <slot name="footer-content" />
    </footer>
  </v-modal>
</template>
