import Cookies from 'js-cookie';

export const authToken = () => {
  const mode = import.meta.env.MODE;
  const tokenNameHash = {
    writer: 'icerik_writer',
    publisher: 'icerik_publisher',
    partnership: 'icerik_publisher_partnership',
  };
  const localStorageItemName = () => {
    const partnershipToken  = localStorage.getItem(tokenNameHash.partnership);
    if(mode === 'writer') {
      return tokenNameHash.writer;
    } else if (mode === 'publisher' && partnershipToken) {
      return tokenNameHash.partnership;
    } else {
      return tokenNameHash.publisher;
    }
  };
  return localStorage.getItem(localStorageItemName());
};

export const moveTokensFromCookieToLocaleStorage = async () => {
  const writerToken = Cookies.get('icerik_writer');
  const publisherToken = Cookies.get('icerik_publisher');
  const publisherPartnerToken = Cookies.get('icerik_publisher_partnership');

  if (writerToken) {
    localStorage.setItem('icerik_writer', writerToken);
  }
  
  if (publisherToken) {
    localStorage.setItem('icerik_publisher', publisherToken);
  }
  
  if (publisherPartnerToken) {
    localStorage.setItem('icerik_publisher_partnership', publisherPartnerToken);
  } else {
    localStorage.removeItem('icerik_publisher_partnership');
  }
};