import { api } from '@/plugins/axios';
import contentStatusEnums from '@/enums/contentStatusEnums'

const projectDetail = {
  state: {
    project: null,
    contents: null,
  },

  getters: {
    project(state) {
      return state.project
    },
    contents(state) {
      return state.contents
    },
  },

  actions: {
    getProjectDetail({ commit }, payload) {
      return api
        .get(`project/${payload.projectID}`)
        .then(response => {
          commit('getProjectDetailHandler', response.data)
        })
    },

    categorizeContents({ commit }, { contents }) {
      const contentsArr = {
        pool: [],
        writer: [],
        editorControl: [],
        revision: [],
        control: [],
        done: [],
        cancel: [],
        all: [],
      }

      contents.forEach(c => {
        const lastSubmissionStatusId =
          c.content_submissions[0]?.content_submission_status_id

        if (lastSubmissionStatusId) {
          // will change
          if ([30, 40].includes(lastSubmissionStatusId)) {
            contentsArr.pool.push(c)
          } 
          else if ([400, 410, 415, ...contentStatusEnums.editorRevise,].includes(lastSubmissionStatusId)) {
            contentsArr.editorControl.push(c)
          }
          else if ([300].includes(lastSubmissionStatusId)) {
            contentsArr.writer.push(c)
          } else if (
            [
              ...contentStatusEnums.publisherRevise,
            ].includes(lastSubmissionStatusId)
          ) {
            contentsArr.revision.push(c)
          } else if (
            [
              ...contentStatusEnums.publisherControl
            ].includes(lastSubmissionStatusId)
          ) {
            contentsArr.control.push(c)
          } else if ([290].includes(lastSubmissionStatusId)) {
            contentsArr.done.push(c)
          } else if (
            contentStatusEnums.cancel.includes(lastSubmissionStatusId)
          ) {
            contentsArr.cancel.push(c)
          }

          contentsArr.all.push(c)
        }
      })

      commit('categorizeContentsHandler', contentsArr)
    },
  },

  mutations: {
    getProjectDetailHandler(state, payload) {
      state.project = payload
    },
    categorizeContentsHandler(state, payload) {
      state.contents = payload
    },
  },
}

export default projectDetail
