import { api } from '@/plugins/axios';

const myAccount = {
  state: {},

  getters: {},

  actions: {
    updateProfile(context, payload) {
      //

      return api
        .put(`profile`, { ...payload })
        .then(response => {
          if (response.status === 200) {
            Vue.notify({
              group: 'success',
              type: 'success',
              text: response.data.detail,
            })
          }
        })
    },

    updateInvoiceAndContactInfo(context, payload) {
      const isCompany = payload.selectedBillingType.id === 1 ? true : false

      const requestData = {
        ...payload,
        company: isCompany,
        tax_administration: !isCompany ? null : payload.tax_administration,
        tax_no: !isCompany ? null : payload.tax_no,
        company_name: !isCompany ? null : payload.company_name,
        iban: isCompany ? null : payload.iban,
        iban_name: isCompany ? null : payload.iban_name,
        national_id: isCompany ? null : payload.national_id_int,
        country: payload.country.name,
        city: payload.city.name,
        district: payload.district.name,
      }
      return api
        .put(`${import.meta.env.VITE_API_URL}/profile`, { ...requestData })
        .then(response => {
          if (response.status === 200) {
            Vue.notify({
              group: 'success',
              type: 'success',
              text: 'Bilgileriniz başarılı bir şekilde güncellendi',
            })
          }
        })
    },

    changePassword(context, payload) {
      const requestData = {
        old_password: payload.currentPassword,
        new_password: payload.newPassword,
      }
      return api
        .put(`${import.meta.env.VITE_API_URL}/profile/password`, {
          ...requestData,
        })
        .then(response => {
          if (response.status === 200) {
            Vue.notify({
              group: 'success',
              type: 'success',
              text: response.data.detail,
            })
          }
        })
    },

    deleteAccount(context, payload) {
      return api
        .delete(`${import.meta.env.VITE_API_URL}/profile`)
        .then(response => {})
    },
  },

  mutations: {},
}

export default myAccount
