import ThePageHeader from '@/components/shared/Header/ThePageHeader';
import Box from '@/components/shared/Box';
import IcerikStatusBoxes from '@/components/shared/IcerikStatusBoxes';

import IcerikCheckbox from '@/components/shared/IcerikCheckbox';
import VLegacyModal from '@/components/molecules/VLegacyModal';
import IcerikNoData from '@/components/shared/IcerikNoData';
import IcerikEditor from '@/components/shared/Editor/IcerikEditor';

export const initGlobalComponents = (app) => {
  // eslint-disable-next-line vue/multi-word-component-names
  app.component('Box', Box);
  app.component('IcerikStatusBoxes', IcerikStatusBoxes);
  app.component('IcerikCheckbox', IcerikCheckbox);
  app.component('ThePageHeader', ThePageHeader);
  app.component('CustomModal', VLegacyModal);
  app.component('IcerikNoData', IcerikNoData);
  app.component('IcerikEditor', IcerikEditor);
};