<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useHead }  from '@unhead/vue';

const route = useRoute();
const layouts = {
  auth: AuthLayout,
  default: DefaultLayout,
};
const activeLayout = computed(() => (route.meta.layout ? layouts[route.meta.layout] : layouts.default));

const mode = import.meta.env.MODE;
const titles = {
  writer: 'Icerikcom Yazar Paneli',
  publisher: 'Icerikcom Yayinci Paneli',
};
const title = titles[mode] || 'Icerikcom';
useHead({
  title: title,
});
</script>

<template>
  <component :is="activeLayout" />
</template>
