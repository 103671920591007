import { useUiStore } from '@/stores/ui';

const initModals = () => {
  const show = (modalName) => {
    const uiStore = useUiStore();
    uiStore.showModal(modalName);
  };

  const hide = (modalName) => {
    const uiStore = useUiStore();
    uiStore.hideModal(modalName);
  };

  return {
    show,
    hide,
  };
};

const ModalPlugin = {
  install(app) {
    const instance = initModals(app);
    app.config.globalProperties.$modal = instance;
  },
};

export default ModalPlugin;
