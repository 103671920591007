<script setup>
const props = defineProps({
  open: { type: Boolean, default: false },
  persistent: { type: Boolean, default: false },
  title: { type: String, default: '' },
  header: { type: Boolean, default: true },
  name: { type: String, default: '' },
});

const emit = defineEmits(['update:open']);

const handleOverlayClick = () => {
  if (!props.persistent) {
    emit('update:open', false);
  }
};
</script>
<template>
  <teleport to="body">
    <dialog
      class="v-modal"
      :open="props.open"
      :name="props.name"
    >
      <div
        class="v-modal__overlay"
        @click="handleOverlayClick"
      />
      <div class="v-modal__container">
        <div
          v-if="props.header"
          class="v-modal__header"
        >
          <slot name="header">
            <div class="v-modal__title">
              {{ title }}
            </div>
            <button @click="emit('update:open', false)">
              <i class="icon-close" />
            </button>
          </slot>
        </div>

        <div class="v-modal__content">
          <slot />
        </div>

        <div class="v-modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </dialog>
  </teleport>
</template>

<style lang="scss">
.v-modal {
  @apply fixed top-0 left-0 w-screen h-screen z-50 bg-transparent;

  &__overlay {
    @apply absolute top-0 left-0 w-full h-full bg-black bg-opacity-50;
  }

  &__container {
    @apply absolute top-1/2 left-1/2 w-screen md:h-auto md:w-auto
      transform -translate-x-1/2 -translate-y-1/2 bg-white
      rounded-lg p-5 min-w-[50%] max-h-[90%] overflow-y-auto;
  }

  &__header {
    @apply flex justify-between items-center;

    button {
      @apply rounded-sm font-sans-medium text-xl ml-3;

      .icon-close {
        @apply text-i-dark text-2xl;
      }
    }
  }

  &__title {
    @apply font-sans-medium text-i-dark;
  }
}
</style>
