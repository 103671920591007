import { api } from '@/plugins/axios';

const balance = {
  state: {
    payments: null
  },

  getters: {
    payments(state) {
      return state.payments;
    }
  },

  actions: {
    getPayments(context, payload) {
      api.get(`${import.meta.env.VITE_API_URL}/payment/history`).then(response => {
        context.commit('getPaymentsHandler', response.data);
      });
    }
  },

  mutations: {
    getPaymentsHandler(state, payload) {
      state.payments = payload;
    } 
  }
};

export default balance;